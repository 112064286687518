import React from "react";

const Footer = () => {
  return (
    <div className='footer'>
      {/* <div className="footbar">
        <a href="#">Help?</a>
        <a href="#">Contact us</a>
      </div> */}
      <div className='app-text-primary'>Copyright @ 2021 JSI</div>
    </div>
  );
};

export default Footer;

import React, { useState, useEffect, useContext, useRef } from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { useHistory } from "react-router-dom";
import axios from "axios";
import logOut from "../Images/LogOut.svg";
import logIn from "../Images/logIn.svg";
// import JSI from "../Images/JSI.png";
import JSI from "../Images/JSI_NewGradient_Official_JSIVelocityWhite_v20230309.png";
import profileImg from "../Images/profileImg.png";
import AuthContext from "../Context/auth/AuthContext";
import { Button, Stack } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
const NavBar = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { isAuthenticated, user, searchFor, search } = authContext;
  // const [userInfo, setUserInfo] = useState(null);
  const [searchShow, setSearchShow] = useState(false);
  // const isAuthenticated = localStorage.getItem("isAuthenticated")
  //   ? localStorage.getItem("isAuthenticated")
  //   : null;
  const [popoverShow, setPopoverShow] = useState(true);
  // useEffect(() => {
  //   console.log("USER isAuthenticated", isAuthenticated);
  // }, [isAuthenticated]);
  // const { isAuthenticated, user, login, logout } = authContext;
  // console.log("USER NAV", user);
  useEffect(() => {
    // console.log("USER NAV UPDATED", user);
    if (user) {
      // setUserInfo(user);
      // console.log("USER NAV INFO UPDATED", user);
    }
  }, [user]);
  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   if (!user) {
  //     try {
  //       axios.get("/user-detail", config).then((response) => {
  //         console.log("USER", response);
  //         setUser(response.data);
  //       });
  //     } catch (err) {
  //       console.log("Error", err);
  //     }
  //   }
  // }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.get("/logout", config).then((response) => {
      // console.log("APPS", response);
      history.push("/");
    });
    localStorage.removeItem("isAuthenticated");
    // logout();
  };

  const searchRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        searchShow &&
        searchRef.current &&
        !searchRef.current.contains(e.target)
      ) {
        setSearchShow(false);
      }
    };

    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [searchShow]);

  // console.log("SHOw", searchShow);

  const handleLogin = (e) => {
    e.preventDefault();
    window.location.href = "/login";
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };
    // axios.get("/logout", config).then((response) => {
    //   console.log("APPS", response);
    //   history.push("/");
    // });
    // login();
  };

  const onSearch = (e) => {
    searchFor(e.target.value);
  };
  const supportEmail = "mailto:Jsi-Velocity_support@jsitel.com";
  const onClearSearch = () => {
    // console.log("clear");
    searchFor("");
  };
  const buttonStyle = {
    backgroundColor: "hsla(0,0%,100%,.2)",
    color: "#fff",
    fontSize: "small",
    width: "100px",
  };

  return (
    <Navbar className='nav-bar'>
      <div className='nav-bar__container'>
        <div className='nav-bar__logo'>
          <div className='nav-bar__logo__img'>
            <img
              src={JSI}
              alt='Logo'
              style={{ width: "10rem", height: "3rem" }}
            />{" "}
          </div>
        </div>
        <div className='nav-bar__menu'>
          {user && isAuthenticated ? (
            <NavDropdown
              id='nav-dropdown'
              className='nav-bar__menu__dropdown2'
              title={
                <div className='nav-bar__menu__dropdown2__toggle'>
                  <div className='nav-bar__menu__dropdown2__toggle__img'>
                    {`${user.firstname.charAt(0)} ${user.lastname.charAt(0)}`}
                  </div>
                  <div>
                    <div>{`${user.firstname} ${user.lastname}`}</div>
                    <div className='nav-bar__menu__dropdown2__toggle__sub-title'>
                      {user.company}
                    </div>
                  </div>
                  <div className='nav-bar__menu__dropdown2__icon'></div>
                </div>
              }
            >
              <NavDropdown.Item
                href='https://login.jsiportal.com/profile2'
                target='-blank'
              >
                My Account
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            popoverShow && (
              <div className='nav-bar__popover'>
                <h5 className='nav-bar__menu__text__title'>
                  Please login to access all the applications
                </h5>
                {/* <h5 className="nav-bar__menu__text__desc">
                  If you need access to the applications, please{" "}
                  <a href="#">contact us</a>
                </h5> */}
              </div>
            )
          )}
          <div>
            <Stack direction='horizontal'>
              <div>
                {user ? (
                  <Button
                    href='/logout'
                    size='small'
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "hsla(0,0%,100%,.2)",
                      color: "#fff",
                      fontSize: "small",
                      width: "100px",
                      marginLeft: "10px",
                      marginRight: "0px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      style={{ marginRight: "5px" }}
                    />
                    LOGOUT
                    {/* <Image src={logOut} alt="log out" style={{ height: "20px", width: "20px" }} roundedCircle /> */}
                  </Button>
                ) : (
                  <Button
                    href='/login'
                    // className="nav-bar__menu__login-btn"
                    onMouseEnter={() => setPopoverShow(false)}
                    onMouseLeave={() => setPopoverShow(true)}
                    size='small'
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "hsla(0,0%,100%,.2)",
                      color: "#fff",
                      fontSize: "small",
                      width: "100px",
                      marginLeft: "10px",
                      marginRight: "0px",
                      paddingLeft: "18px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSignInAlt}
                      style={{ marginRight: "5px" }}
                    />
                    LOGIN
                    {/* <Image src={logIn} alt="log in" roundedCircle style={{ height: "20px", width: "20px" }} /> */}
                  </Button>
                )}
              </div>
              <Button
                href={supportEmail}
                size='small'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "hsla(0,0%,100%,.2)",
                  color: "#fff",
                  fontSize: "small",
                  width: "100px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ marginRight: "5px" }}
                />
                SUPPORT
              </Button>
            </Stack>
          </div>

          {search === "" || search === null || !searchShow ? (
            <i
              alt='search'
              className={
                searchShow
                  ? "fas fa-search nav-bar__menu__search__icon show"
                  : "fas fa-search nav-bar__menu__search__icon"
              }
              onClick={() => setSearchShow(!searchShow)}
            ></i>
          ) : (
            <i
              alt='search'
              className={
                searchShow
                  ? "fas fa-times nav-bar__menu__search__icon show"
                  : "fas fa-times nav-bar__menu__search__icon"
              }
              onClick={() => onClearSearch()}
            ></i>
          )}
          <input
            ref={searchRef}
            type='text'
            value={search}
            placeholder='Search'
            className={
              searchShow
                ? "nav-bar__menu__search show"
                : "nav-bar__menu__search"
            }
            onChange={(e) => onSearch(e)}
          />
        </div>
      </div>
    </Navbar>
  );
};

export default NavBar;

import React, { useReducer, useEffect } from "react";
import axios from "axios";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";

const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const APPS_LOADING = "APPS_LOADING";
const FETCH_USER_APPS = "FETCH_USER_APPS";
const FETCH_APPS = "FETCH_APPS";
const FETCH_APPS_ERROR = "FETCH_APPS_ERROR";
const SEARCH = "SEARCH";

const AuthState = (props) => {
  // const localState = window.localStorage.getItem("jsi-portal-state");
  const initialState = {
    isAuthenticated: false,
    user: null,
    error: null,
    apps: null,
    appsLoaded: false,
    appsLoading: false,
    search: null,
  };
  //const [state, dispatch] = useReducer(AuthReducer, localState || initialState);

  const [state, dispatch] = useReducer(AuthReducer, initialState);
  useEffect(() => {
    const localState = JSON.stringify(state);
    // try {
    //   window.localStorage.setItem("jsi-portal-state", localState);
    // } catch (err) {
    //   console.log("Error in storing local state");
    // }
    // console.log("LOGIN state", localState, localState.user);
  }, [state]);

  const login = async () => {
    // console.log("INSIDE LOg");
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await axios.get("/user-detail", config);
        // console.log("AUTH user", resp.data);
        dispatch({
          type: LOGIN,
          payload: { user: resp.data },
        });
        resolve(resp.data);
      } catch (err) {
        // console.log("Error", err);
        reject(err);
      }
    });
  };

  const loadUser = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get("/user-detail", config);
        // console.log("USER STATE", res);
        // localStorage.setItem("isAuthenticated", JSON.stringify(response.data));
        dispatch({
          type: LOGIN,
          payload: { user: res.data.user },
        });
        resolve(res.data.user);
      } catch (err) {
        // console.log("Error", err);
        reject(null);
      }
    });
  };

  const loadUserApps = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: APPS_LOADING,
        });
        const res = await axios.get("/user-apps", config);
        // console.log("USER Apps STATE", res);
        // localStorage.setItem("isAuthenticated", JSON.stringify(response.data));
        // const newTmpApps = res.data.map((apps) => {
        // console.log("apps.portalTab", apps.portalTab);
        // if (apps.portalTab === undefined) {
        //   return {
        //     ...apps,
        //     ["configuration"]: {
        //       login_url: "https://jsi-cpr.azurewebsites.net/oauth/callback",
        //     },
        //     ["portalTab"]: "Financial",
        //   };
        // }
        // return { apps };
        // });
        dispatch({
          type: FETCH_USER_APPS,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        // console.log("Error", err);
        dispatch({
          type: FETCH_APPS_ERROR,
          payload: { message: "Error in Fetching Apps", status: 505 },
        });
        reject(null);
      }
    });
  };

  const getAppsList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: APPS_LOADING,
        });
        const res = await axios.post("/appslist", {}, config);
        // console.log("APPS", res);
        dispatch({
          type: FETCH_APPS,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        // console.log("Error Fetching APPs", err);
        dispatch({
          type: FETCH_APPS_ERROR,
          payload: { message: "Error in Fetching Apps", status: 504 },
        });
        reject(null);
      }
    });
  };

  const logout = () => {
    dispatch({
      type: LOGOUT,
    });
  };

  const searchFor = (search) => {
    // console.log("Search for", search);
    dispatch({
      type: SEARCH,
      payload: search,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        loadUser,
        loadUserApps,
        getAppsList,
        searchFor,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;

import React from "react";
import AppLink from "./AppLink";

const CardList = ({ cardArray, user }) => {
  return (
    <div className="card-list">
      {cardArray.map((ele) => (
        <AppLink cardDetails={ele} user={user} key={ele.id} />
      ))}
    </div>
  );
};

export default CardList;

import React, { useContext, useEffect, useState } from "react";
import { Spinner, Form, Row, Container, Col } from "react-bootstrap";
import { Stack } from "react-bootstrap";

import CardList from "../Components/CardList";
import AuthContext from "../Context/auth/AuthContext";
import { Link } from "react-router-dom";
import TabConfig from "../Config/TabConfig.json";
import TabCategory from "../Config/TabCategory.json";
import { Tab, Nav } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
const ViewAll = () => {
  const authContext = useContext(AuthContext);
  const {
    isAuthenticated,
    user,
    apps,
    loadUserApps,
    getAppsList,
    appsLoaded,
    appsLoading,
    loadUser,
    search,
  } = authContext;
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [userName, setUserName] = useState("");
  const [showTab, setShowTab] = useState(false);

  // const [apps, setApps] = useState([]);
  const [loaded, setLoaded] = useState(false);
  // console.log("USER APPS", user);

  const loadAppsList = async () => {
    const tmpApps = await getAppsList();
    // console.log("PUB APPS REFRESHED", tmpApps);
    // setVisibleApps(tmpApps);
  };
  const fetchUserInfo = async () => {
    try {
      console.log("Logged user:", user);
      const userId = user?.email;
      //  const userId = "inftest.user@gmail.com"
      const isJsiUser =
        userId?.includes("jsiportal.com") || userId?.includes("jsitel.com");
      console.log("userId", userId, "isJsiUser", isJsiUser);
      try {
        if (userId && !isJsiUser) {
          const res = await axios.get(`/user-info?userid=${userId}`);
          console.log("res", res);
          const isAcknowledge = res.data.TNC_ACKNOWLEDGED;
          console.log("isAcknowledge", isAcknowledge);
          if (isAcknowledge === true) {
            setShow(false);
          } else {
            setShow(true);
          }
        }
      } catch (error) {
        console.log("ERR-USER-INFO", error);
      }
    } finally {
      setShowTab(true);
    }
  };
  useEffect(() => {
    console.log("userCheck", user);

    fetchUserInfo();
  }, []);
  console.log("showTab", showTab);
  console.log("userNameInfo", userName);
  const fetchUserApps = async () => {
    const tmpApps = await loadUserApps();
    // console.log("USER APPS REFRESHED", tmpApps);
    // setVisibleApps(tmpApps);
  };

  const [selectedTabs, setSelectedTabs] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    // const getAppsList = async () => {
    //   const config = {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   };
    //   // if (users.length === 0)
    //   //   axios.get("/users.json").then((response) => {
    //   //     // setPortal(...portal, { users: response.data });
    //   //   });
    //   if (apps.length === 0)
    //     await axios
    //       .post("/appslist", {}, config)
    //       .then((response) => {
    //         console.log("APPS", response);
    //         const apps = response.data.filter((item) => !item.exclude);
    //         setApps(apps);
    //         setLoaded(true);
    //       })
    //       .catch((err) => {
    //         console.log("Error Fetching APPs", err);
    //         setLoaded(true);
    //       });
    // };
    // getAppsList();
    if (!user && !apps && !appsLoaded) {
      loadAppsList();
    }
  }, []);

  useEffect(() => {
    if (user) {
      // console.log("USER APPS REFRESHING...");
      fetchUserApps();
    }
  }, [user]);

  const getAppsByTab = (tabName) => {
    if (shouldShowAll) return apps;
    else return apps.filter((item) => appsLoaded && item.portalTab === tabName);
  };

  const handleCheckbox = (e) => {
    const value = e.target.value;
    if (selectedTabs.includes(value)) {
      setSelectedTabs(selectedTabs.filter((tab) => tab !== value));
    } else {
      setSelectedTabs([...selectedTabs, value]);
    }
  };

  const getAppsByTabAndCategory = (tabName) => {
    return apps.filter(
      (item) =>
        appsLoaded &&
        item.portalTab === tabName &&
        (selectedCategories.length === 0 ||
          selectedCategories.includes(item.category))
    );
  };

  const getAppsBySearchVal = (cardArray) => {
    // console.log("getAppsBySearchVal", search);
    return cardArray.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
  };

  const getCardArray = () => {
    // console.log("getCardArray");

    let cardArray = [];
    if (apps && apps.length > 0) {
      if (shouldShowAll) {
        cardArray = apps.filter(
          (item) =>
            selectedCategories.length === 0 ||
            selectedCategories.includes(item.category)
        );
      } else {
        cardArray = getAppsByTabAndCategory(selectedTabs[0]);
      }
      // console.log("getCardArray", cardArray);

      if (search) {
        cardArray = getAppsBySearchVal(cardArray);
      }
    }
    // console.log("getCardArray cardArray", cardArray);
    return cardArray;
  };

  useEffect(() => {
    getCardArray();
  }, [apps, search]);

  const handleCategory = (e) => {
    const value = e.target.value;
    if (selectedCategories.includes(value)) {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== value)
      );
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }
  };

  const shouldShowAll =
    selectedTabs.length === 0 || selectedTabs.length === TabConfig.length;
  const handleContinue = async () => {
    if (isChecked) {
      handleClose();
      const userId = user.email;
      try {
        const res = await axios.post(
          `/update-user-acknowledgement?userid=${userId}`
        );
        console.log("res", res);
      } catch (error) {
        console.log("errorUserInf", error);
      }
      console.log("User accepted the terms and conditions");
    }
  };

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  return (
    <div className="view-all">
      {showTab ? (
        <Tab.Container defaultActiveKey="all" id="view-all-tabs">
          <div className="main-container">
            <div style={{ minWidth: "13rem", paddingLeft: "1.2rem" }}>
              <Stack gap={{ xs: 1, sm: 1, md: 2, lg: 3 }}>
                <div>
                  <div>
                    <h5 className="app-text-primary-bold">Apps</h5>
                    <hr />
                  </div>
                  <div>
                    <Stack gap={{ xs: 1, sm: 1, md: 2, lg: 3 }}>
                      {TabConfig.map((ele) => (
                        <Form key={ele.tabTitle}>
                          <Form.Check
                            type="checkbox"
                            id={ele.tabTitle}
                            label={ele.tabTitle}
                            value={ele.tabTitle}
                            checked={selectedTabs.includes(ele.tabTitle)}
                            onChange={handleCheckbox}
                            className="app-text-primary"
                          />
                        </Form>
                      ))}
                    </Stack>
                  </div>
                </div>
                <div>
                  <div>
                    <h5 className="app-text-primary-bold">Category</h5>
                    <hr />
                  </div>

                  <div>
                    <Stack gap={{ xs: 1, sm: 1, md: 2, lg: 3 }}>
                      {TabCategory.map((ele) => (
                        <Form.Check
                          type="checkbox"
                          id={ele.tabCategory}
                          label={ele.tabCategory}
                          value={ele.tabCategory}
                          checked={selectedCategories.includes(ele.tabCategory)}
                          onChange={handleCategory}
                          className="app-text-primary"
                        />
                      ))}
                    </Stack>
                  </div>
                </div>
              </Stack>
            </div>

            <div lg={10} style={{ flexGrow: 1 }}>
              <Tab.Content>
                <Tab.Pane eventKey="all">
                  {apps && apps.length > 0 && !appsLoading ? (
                    <CardList cardArray={getCardArray()} user={user} />
                  ) : (
                    <Spinner
                      animation="border"
                      variant="primary"
                      className="home__card-carousel__spinner"
                    />
                  )}
                </Tab.Pane>
                {TabConfig.map((ele) => (
                  <Tab.Pane key={ele.tabTitle} eventKey={ele.tabTitle}>
                    {appsLoaded && (
                      <CardList
                        cardArray={
                          shouldShowAll || selectedTabs.includes(ele.tabTitle)
                            ? getAppsByTab(ele.tabTitle)
                            : []
                        }
                      />
                    )}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      ) : null}

      <Modal scrollable size="lg" centered backdrop="static" show={show}>
        <Modal.Header>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div>
            <h5>JSI SOFTWARE END USER LICENSE AGREEMENT</h5>
            <p>
              THIS SOFTWARE END USER LICENSE AGREEMENT (THE “AGREEMENT”) IS A
              LEGAL AGREEMENT BETWEEN YOU (“CUSTOMER“) AND JOHN STAURULAKIS, LLC
              (“JSI”). BY CLICKING THE “I ACCEPT” BUTTON, CUSTOMER ACKNOWLEDGES
              THAT CUSTOMER HAS REVIEWED AND ACCEPTS THIS AGREEMENT. IF YOU ARE
              AGREEING TO THIS AGREEMENT AS AN INDIVIDUAL “CUSTOMER” REFERS TO
              YOU INDIVIDUALLY. IF YOU ARE AGREEING TO THIS AGREEMENT AS A
              REPRESENTATIVE OF AN ENTITY, YOU REPRESENT THAT YOU HAVE THE
              AUTHORITY TO BIND THAT ENTITY AND “CUSTOMER” REFERS TO THAT ENTITY
              AND ALL THE USERS. JSI MAY MAKE CHANGES TO THIS AGREEMENT AT ANY
              TIME. CUSTOMER’S CONTINUED USE OF THE JSI SOFTWARE AFTER SUCH
              CHANGES HAVE BEEN POSTED WILL SIGNIFY CUSTOMER’S ASSENT TO AND
              ACCEPTANCE OF THE REVISED AGREEMENT.
            </p>

            <h5>1. LICENSES </h5>
            <p>
              <b>1.1. Access and Use License</b>. Subject to Customer’s
              compliance with the terms and conditions contained in this
              Agreement and to payment of any applicable fees, JSI hereby grants
              to Customer, during the relevant Term, a limited, non-exclusive,
              non-transferable right to access and use the Software in
              accordance with the Documentation, in each case solely for
              Customer’s internal business purposes and not for the benefit of
              any other person or entity.
            </p>
            <p>
              <b> 1.2. Subscription Products</b>. For Software offered on a
              subscription basis, JSI offers a limited, personal,
              non-sublicensable, non-transferable, non-exclusive license for the
              duration of the applicable term or subscription period purchased
              by Customer to: (i) access, and use the Subscription Product, and
              (ii) exercise any other rights applicable to the Subscription
              Product as expressly set forth in the applicable Documentation for
              such Software. Each instance of the applicable Subscription
              Product may be used only for the term of the license purchased by
              Customer as set forth on the applicable order form. Customer shall
              be solely responsible for each user that accesses the Software,
              and for all data created by use of or access to or stored in the
              Software.
            </p>
            <p>
              <b>1.3. Reservation of Rights</b>. Except as expressly granted in
              this Agreement, there are no other licenses granted to Customer,
              express, implied or by way of estoppel. All rights not granted in
              this Agreement are reserved by JSI.
            </p>

            <h5>2. PROPRIETARY RIGHTS</h5>
            <p>
              <b> 2.1. JSI Software</b>. As between JSI and Customer, all right,
              title and interest in the Software, Support Services and any other
              JSI materials furnished or made available hereunder, and all
              modifications and enhancements thereof, and all suggestions, ideas
              and feedback proposed by Customer regarding the Software,
              including all copyright rights, patent rights and other
              Intellectual Property Rights in each of the foregoing, belong to
              and are retained solely by JSI or JSI’s licensors and providers,
              as applicable. Customer hereby does and will irrevocably assign to
              JSI all evaluations, ideas, feedback, and suggestions made by
              Customer to JSI regarding JSI’s Software, Documentation and
              Support Services (collectively, “Feedback”) and all Intellectual
              Property Rights in the Feedback.
            </p>
            <p>
              <b>2.2. Customer Data</b>. As between JSI and Customer, all
              rights, title and interest in the Customer Data, belongs to and
              are retained solely by Customer. Customer hereby grants to JSI a
              limited, non-exclusive, royalty-free, worldwide license to use the
              Customer Data and perform all acts with respect to the Customer
              Data as may be necessary for JSI to provide Support Services to
              Customer. To the extent that receipt of the Customer Data requires
              JSI to utilize any account information from a third-party service
              provider, Customer shall be responsible for obtaining and
              providing relevant account information and passwords, and JSI
              hereby agrees to access and use the Customer Data solely for
              Customer’s benefit and as set forth in this Agreement. As between
              JSI and Customer, Customer is solely responsible for the accuracy,
              quality, integrity, legality, reliability, and appropriateness of
              all Customer Data and the rights to use and access such Customer
              Data. Customer has obtained all necessary consents, as applicable,
              for the processing and transferring of Customer Data to JSI.
              Customer shall defend, indemnify and hold Enablon and its
              Affiliates harmless from any claim, action, suit, damage, judgment
              or cost, including attorney’s fees, based upon or arising out of
              the custody, possession, storage, transmission or management of
              Customer Data in accordance with the terms of this Agreement,
              including without limitation claims predicated on any law or
              regulation concerning protection of personal data or rights in
              data collections.
            </p>

            <h5>3. PASSWORDS; SECURITY</h5>
            <p>
              <b>3.1. Passwords</b>. Where applicable, Customer shall choose
              user logins and passwords to use the Software. Customer shall be
              responsible for maintaining the confidentiality of all user logins
              and passwords and for ensuring that each user login and password
              is used only by the user to which it was issued. Customer agrees
              to immediately notify JSI of any unauthorized use of any account
              or login and password issued to Customer or any other breach of
              security known to Customer. JSI shall have no liability for any
              loss or damage arising from Customer’s failure to comply with the
              terms set forth in this Section
            </p>
            <p>
              <b>3.2. No Circumvention of Security</b>. Customer may not
              circumvent or otherwise interfere with any user authentication or
              security of the Software. Customer will immediately notify JSI of
              any breach, or attempted breach, of security known to Customer.
            </p>
            <p>
              <b>3.3. Security</b>. JSI will use commercially reasonable efforts
              to maintain appropriate administrative, physical and technical
              safeguards for protection of the security, confidentiality and
              integrity of Customer Data. Notwithstanding the foregoing,
              Customer acknowledges that, notwithstanding any security
              precautions deployed by JSI, the use of, or connection to, the
              Internet provides the opportunity for unauthorized third parties
              to circumvent such precautions and illegally gain access to the
              Software and the data processed thereon including Customer Data.
              JSI cannot and does not guarantee the privacy, security, integrity
              or authenticity of any information transmitted over or stored in
              any system connected to or accessible via the Internet or
              otherwise or that any such security precautions will be adequate
              or sufficient. JSI shall not (a) modify Customer Data, except to
              the extent necessary to provide Support Services and prevent or
              address service or technical problems, (b) disclose Customer Data
              except as compelled by law or as expressly permitted in writing by
              Customer, or (c) access Customer Data except to provide the
              Support Services and prevent or address service or technical
              problems, or at Customer’s request in connection with customer
              support matters. All Customer Data accessed or, processed or
              analyzed by or stored shall be subject to the JSI privacy policy
              located at{" "}
              <a href="https://jsitel.com/privacy-policy/">
                https://jsitel.com/privacy-policy/
              </a>
            </p>

            <h5>4. CUSTOMER OBLIGATIONS</h5>
            <p>
              <b>4.1. Acceptable Use Policy</b>. Customer shall be solely
              responsible for its actions while using the Software and Support
              Services. Customer acknowledges and agrees: (a) to abide by all
              local, state, national, and international laws and regulations
              applicable; (b) not to send or store data on the Software which
              violates the rights of any individual or entity established in any
              jurisdiction; (c) not to upload in any way any information or
              content that contain viruses, worms, time bombs, trojan horses and
              other harmful or malicious code, files, scripts, agents or
              programs or data that may damage the operation of the Software;
              (d) not to use the Software for illegal, fraudulent, unethical or
              inappropriate purposes; (e) not to interfere with the use of the
              Software; (f) not to distribute, promote or transmit through the
              Software any unlawful, harmful, obscene, pornographic or otherwise
              objectionable material of any kind or nature; (g) not to transmit
              or post any material that encourages conduct that could constitute
              a criminal offense or give rise to civil liability; (h) not to use
              the Software in any manner that impairs such Software; and (i) to
              use the Software only in accordance with the Documentation.
              Customer acknowledges and agrees that JSI neither endorses the
              contents of any Customer communications, Customer Data, or Other
              Information, nor assumes any responsibility for any offensive
              material contained therein, any infringement of third party
              Intellectual Property Rights arising therefrom or any crime
              facilitated thereby. JSI may remove any violating content posted
              or stored using the Software. Notwithstanding the foregoing, JSI
              does not guarantee, and does not and is not obligated to verify,
              authenticate, monitor or edit the Customer Data, Other
              Information, or any other information or data input into or stored
              in or in connection with use of the Software for completeness,
              integrity, quality, accuracy or otherwise.
            </p>
            <p>
              <b>4.2. Suspension</b>. JSI may suspend Customer’s access to the
              Software in the event that either Customer any of their Users is
              engaged in, or JSI in good faith suspects Customer is engaged in,
              any unauthorized conduct (including, but not limited to any
              violation of this Agreement). Additionally, if there are repeated
              incidences of suspension, regardless of the same or different
              cause and even if the cause or conduct is ultimately cured or
              corrected, JSI may, in its reasonable discretion, determine that
              such circumstances, taken together, constitute a material breach.
            </p>

            <h5>5. REPRESENTATIONS AND WARRANTIES DISCLAIMER</h5>
            <p>
              <b>5.1. DISCLAIMER</b>. THE SOFTWARE IS PROVIDED ON AN AS-IS
              BASIS. CUSTOMER’S USE OF THE SOFTWARE IS AT ITS OWN RISK. JSI DOES
              NOT MAKE, AND HEREBY DISCLAIMS, ANY AND ALL OTHER EXPRESS,
              STATUTORY AND IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO,
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              NONINFRINGEMENT AND TITLE, QUALITY, SUITABILITY, OPERABILITY,
              CONDITION, SYSTEM INTEGRATION, NON-INTERFERENCE, WORKMANSHIP,
              TRUTH, ACCURACY (OF DATA OR ANY OTHER INFORMATION OR CONTENT),
              ABSENCE OF DEFECTS, WHETHER LATENT OR PATENT, AND ANY WARRANTIES
              ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE. ANY
              WARRANTIES MADE BY JSI ARE FOR THE BENEFIT OF CUSTOMER AND NOT FOR
              THE BENEFIT OF ANY THIRD PARTY. ANY MATERIALS PROVIDED ARE
              LICENSED AND NOT SOLD. JSI DOES NOT WARRANT THAT: (A) THE USE OF
              THE SOFTWARE WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE
              OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE,
              SYSTEM OR DATA; (B) THE SOFTWARE WILL MEET CUSTOMER’S REQUIREMENTS
              OR EXPECTATIONS; (C) ANY STORED DATA WILL BE ACCURATE OR RELIABLE;
              (D) THE QUALITY OF ANY INFORMATION OR OTHER MATERIAL OBTAINED BY
              CUSTOMER THROUGH THE SOFTWARE WILL MEET CUSTOMER’S REQUIREMENTS OR
              EXPECTATIONS; OR (E) THE SOFTWARE WILL ADHERE TO AND SATSIFY ALL
              LEGAL AND REGULATORY REQUIREMENTS IMPOSED ON YOU AS A CUSTOMER.
              JSI IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER
              DAMAGES RESULTING FROM SUCH PROBLEMS.
            </p>
            <p>
              <b> 5.2. Not a Legal Service</b>. Through this Software, JSI
              provides an online portal which is intended to be used as a
              document management and automated software solution. JSI’s
              Software and websites may also include general information on
              commonly encountered legal issues. JSI will not review documents
              uploaded to the Software for legal sufficiency, draw legal
              conclusions, provide legal advice, opinions or recommendations
              about your legal rights, remedies, defenses, options, selection of
              forms, or strategies, or apply the law to the facts of your
              particular situation to any document uploaded to the Software. JSI
              is not a law firm and may not perform services performed by an
              attorney. JSI, its Software and services, and its forms or
              templates are not a substitute for the advice or services of an
              attorney.
            </p>

            <h5>6. CONFIDENTIALITY</h5>
            <p>
              <b>6.1. Nature and Scope</b>. As used herein, “Confidential
              Information” means any non-public, confidential information either
              marked as such or, in the relevant circumstances, should be
              understood to be confidential information and includes (i)
              business and financial information of the subject transaction of
              the parties, (ii) business methods and practices involved in the
              subject transactions, (iii) marketing strategies in the subject
              transactions, (iv) all internal policies, procedures or
              third-party audit or attestation reports, (v) the terms of this
              Agreement, and (vi) any customer data transferred or access during
              or related to the provision of Services under this Agreement.
              Information shall be considered confidential whether furnished or
              made available before or after the date of this Agreement, and
              regardless of its form, format, media or mode of disclosure
              (written, visual, electronic or other).
            </p>
            <p>
              <b>6.2. Obligations</b>. Each party will keep all Confidential
              Information of the other party strictly confidential. Each party
              agrees to use the same care to protect the Confidential
              Information of the other as it employs with similar information of
              its own (but in no event less than reasonable care). Neither party
              will disclose any Confidential Information of the other party,
              except that each party may disclose Confidential Information of
              the other to its employees, contractors or agents who have a need
              to know such information, provided that, prior to such disclosure,
              each such employee, contractor or agent is bound by obligations to
              comply with the restrictions on use and disclosure of Confidential
              Information set forth in this Agreement. The parties further agree
              that they will use Confidential Information solely for the
              purposes for which such information, or access to it, is provided
              pursuant to the terms of this Agreement. In addition, Parties
              shall be responsible for its subcontractors’ full compliance with
              the confidentiality obligations hereunder. These confidentiality
              obligations shall survive for the longer of (i) a period of five
              (5) years after termination of this Agreement; or (ii) the longest
              period permitted by law.
            </p>
            <p>
              <b>6.3. Exceptions</b>. Confidential Information shall not include
              information which is: (i) independently developed by the party
              without the benefit of the other's disclosure or is already known
              by the party at the time of disclosure; (ii) approved for release
              by the other's written authorization or is rightfully received by
              the party from a third party without any obligation of
              confidentiality; (iii) public knowledge without the wrongful act
              or breach of this Agreement by either party; or (iv) disclosed
              pursuant to the requirements of a governmental agency or court
              order.
            </p>
            <p>
              <b>6.4. Destruction of Confidential Information</b>. Upon the
              effective date of the expiration or termination of this Agreement
              for any reason, either Party may request in writing and the
              non-requesting Party shall either, with respect to Confidential
              Information to which such non-requesting Party does not retain
              rights under the surviving provisions of this Agreement, promptly
              destroy all copies of such Confidential Information in the
              possession or control of the non-requesting Party and confirm such
              destruction in writing to the requesting Party. Notwithstanding
              the foregoing, the non-requesting Party shall be permitted to
              retain (i) such Confidential Information to the extent necessary
              or useful for purposes of performing any continuing obligations or
              exercising any ongoing rights hereunder and, in any event, a
              single copy of such Confidential Information for archival purposes
              and (ii) any computer records or files containing such
              Confidential Information that have been created solely by such
              non-requesting Party’s automatic archiving and back-up procedures,
              to the extent created and retained in a manner consistent with
              such non-requesting Party’s standard archiving and back-up
              procedures, but not for any other uses or purposes. All
              Confidential Information shall continue to be subject to the terms
              of this Agreement.
            </p>

            <h5>7. LIMITATION OF LIABILITY</h5>
            <p>
              <b>7.1. No Consequential Damages</b>. NEITHER JSI NOR ITS
              LICENSORS OR CUSTOMER SHALL BE LIABLE FOR ANY INDIRECT,
              INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY
              DAMAGES FOR LOST DATA, BUSINESS INTERRUPTION, LOST PROFITS, LOST
              REVENUE OR LOST BUSINESS, ARISING OUT OF OR IN CONNECTION WITH
              THIS AGREEMENT, EVEN IF JSI OR ITS LICENSORS OR CUSTOMER HAVE BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING WITHOUT
              LIMITATION, ANY SUCH DAMAGES ARISING OUT OF THE LICENSING,
              PROVISION OR USE OF THE SOFTWARE OR SUPPORT SERVICES, OR ANY
              ANCILLARY SERVICES OR THE RESULTS THEREOF. JSI WILL NOT BE LIABLE
              FOR THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES.
            </p>
            <p>
              <b>7.2. Limits on Liability</b>. THE TOTAL AGGREGATE LIABILITY OF
              JSI AND ITS LICENSORS IN RESPECT OF ALL CAUSES OF ACTION ARISING
              OUT OF OR IN CONNECTION WITH THESE TERMS (WHETHER FOR BREACH OF
              CONTRACT, STRICT LIABILITY, TORT (INCLUDING NEGLIGENCE), PRODUCT
              LIABILITY OR OTHERWISE) SHALL NOT EXCEED THE AMOUNTS PAID BY
              CUSTOMER TO JSI UNDER THESE TERMS DURING THE PERIOD OF SIX (6)
              MONTHS PRECEDING THE DATE ON WHICH THE CLAIM FIRST ACCRUED.
            </p>
            <p>
              <b>7.3. Essential Purpose</b>. CUSTOMER ACKNOWLEDGES THAT THE
              TERMS IN THIS SECTION 11 (LIMITATION OF LIABILITY) SHALL APPLY TO
              THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND SHALL APPLY
              EVEN IF AN EXCLUSIVE OR LIMITED REMEDY STATED HEREIN FAILS OF ITS
              ESSENTIAL PURPOSE.
            </p>

            <h5>8. MISCELLANEOUS</h5>
            <p>
              <b>8.1. Governing Law</b>. This Agreement and the rights and
              obligations of the parties to and under this Agreement shall be
              governed by and construed under the laws of the United States and
              the State of Delaware.
            </p>
            <p>
              <b>8.2. Force Majeure</b>. Except for your payment obligations
              hereunder, neither party shall be liable to the other party or any
              third party for failure or delay in performing its obligations
              under these Terms when such failure or delay is due to any cause
              beyond the control of the party concerned, including, without
              limitation, force majeure, governmental orders or restrictions,
              fire, or flood, provided that upon cessation of such events such
              party shall thereupon promptly perform or complete the performance
              of its obligations hereunder
            </p>
            <p>
              <b>8.3. Export</b>. Customer acknowledges and agrees that the
              Software shall not be used, and none of the underlying
              information, software, or technology may be transferred or
              otherwise exported or re-exported to countries as to which the
              United States maintains an embargo (collectively, “Embargoed
              Countries”), or to or by a national or resident thereof, or any
              person or entity on the U.S. Department of Treasury’s List of
              Specially Designated Nationals or the U.S. Department of
              Commerce’s Table of Denial Orders (collectively, “Designated
              Nationals”). The lists of Embargoed Countries and Designated
              Nationals are subject to change without notice. By using the
              Software offered by JSI, Customer represents and warrants that it
              is not located in, under the control of, or a national or resident
              of an Embargoed Country or Designated National. The Software may
              use encryption technology that is subject to licensing
              requirements under the U.S. Export Administration Regulations, 15
              C.F.R. Parts 730-774 and Council Regulation (EC) No. 1334/2000.
              Customer agrees to comply strictly with all applicable export laws
              and assume sole responsibility for obtaining licenses to export or
              re-export as may be required. JSI and its licensors make no
              representation that the Software is appropriate or available for
              use in other locations.
            </p>
            <p>
              <b>8.4. General</b>. Customer shall not assign its rights
              hereunder, or delegate the performance of any of its duties or
              obligations hereunder, whether by merger, acquisition, sale of
              assets, operation of law, or otherwise, without the prior written
              consent of JSI. Any purported assignment in violation of the
              preceding sentence is null and void. Subject to the foregoing,
              this Agreement shall be binding upon, and inure to the benefit of,
              the successors and assigns of the parties thereto. Except as
              otherwise specified in this Agreement, this Agreement may be
              amended or supplemented only by a writing that refers explicitly
              to this Agreement and that is signed on behalf of both parties. No
              waiver will be implied from conduct or failure to enforce rights.
              No waiver will be effective unless in a writing signed on behalf
              of the party against whom the waiver is asserted
            </p>
            <p>
              <b>8.5. Entire Agreement</b>. This Agreement constitutes the
              entire agreement between the parties relating to this subject
              matter and supersedes all prior or simultaneous understandings,
              representations, discussions, negotiations, and agreements,
              whether written or oral.
            </p>

            <h5>9. DEFINITIONS</h5>
            <p>
              <b>“Customer Data”</b> means all data submitted, stored, posted,
              displayed, transmitted or otherwise provided by or on behalf of
              Customer.
            </p>
            <p>
              <b>“Documentation”</b> means the then-current written and/or
              electronic end user instructions, technical documentation and help
              files pertaining to the applicable Software that is provided by
              JSI together with the delivery of the applicable Software or
              otherwise made available by JSI with respect to a particular
              Product.
            </p>
            <p>
              <b>“Intellectual Property Rights”</b> means all intellectual
              property rights or similar proprietary rights of JSI associated
              with the Software, Subscription Product, Support Services and
              Documentation, including, but not limited to (a) patent rights and
              utility models, (b) copyrights and database rights, (c)
              trademarks, trade names, domain names and trade dress and the
              goodwill associated therewith, (d) trade secrets, (e) mask works,
              and (f) industrial design rights; in each case, including any
              registrations of, applications to register, and renewals and
              extensions of, any of the foregoing in any jurisdiction in the
              world.
            </p>
            <p>
              <b> “Software”</b> means any software owned by or licensed by JSI,
              including (i) software provided on a stand-alone basis, (ii)
              software provided as part of SaaS or cloud services, and (iii) any
              upgrades, updates, patches, enhancements, or fixes to any of the
              foregoing that may be made available by JSI.
            </p>
            <p>
              <b>“Subscription Product”</b> means any Software that is licensed
              by JSI on a term or subscription basis that expires after the
              applicable term or subscription period ends as set forth in the
              applicable ordering documentation received by JSI.
            </p>
            <p>
              <b>“Support Services”</b> means the support and maintenance
              services offered by JSI.
            </p>
            <p>
              <b>“Term”</b> means the Subscription Product term of use for a
              period identified in the applicable ordering documentation
              received by JSI.
            </p>
            <div>
              <Form.Check
                type="checkbox"
                label="I agree to the Terms and Conditions"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Form.Check
            type="checkbox"
            label="I agree to the Terms and Conditions"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          /> */}
          <Button
            variant="primary"
            onClick={handleContinue}
            disabled={!isChecked}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewAll;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import AuthState from "./Context/auth/AuthState";
import "./fonts/Avenir-Next-LT-Pro-Bold.otf";
import "./fonts/Avenir-Next-LT-Pro-Demi.otf";

ReactDOM.render(
  <React.StrictMode>
    <AuthState>
      <Router>
        <App />
      </Router>
    </AuthState>
  </React.StrictMode>,
  document.getElementById("root")
);

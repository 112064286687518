const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const APPS_LOADING = "APPS_LOADING";
const FETCH_USER_APPS = "FETCH_USER_APPS";
const FETCH_APPS = "FETCH_APPS";
const FETCH_APPS_ERROR = "FETCH_APPS_ERROR";
const SEARCH = "SEARCH";

// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case FETCH_USER_APPS:
      return {
        ...state,
        apps: action.payload,
        appsLoading: false,
        appsLoaded: true,
      };
    case FETCH_APPS:
      return {
        ...state,
        apps: action.payload,
        appsLoading: false,
        appsLoaded: true,
      };
    case APPS_LOADING:
      return {
        ...state,
        appsLoading: true,
        appsLoaded: false,
      };
    case FETCH_APPS_ERROR:
      return {
        ...state,
        appsLoading: false,
        appsLoaded: false,
        error: action.payload,
      };
    case SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

import React, { useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import UserApps from "./UserApps";
import ViewAll from "./Pages/ViewAllPage";
import AuthContext from "./Context/auth/AuthContext";
// import "./styles.css";
import "./App.scss";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";

const App = () => {
  const authContext = useContext(AuthContext);
  const { user, loadUser } = authContext;
  const [userChecked, setUserChecked] = useState(false);

  const fetchUserInfo = async () => {
    try {
      const tmpUser = await loadUser();
      // console.log("USER INFO LOADED", tmpUser);
      setUserChecked(true);
      // setAppUser(tmpUser);
    } catch (err) {
      // console.log("USER INFO NOT AVAILABLE");
      setUserChecked(true);
    }
  };

  useEffect(() => {
    if (!user) {
      fetchUserInfo();
    }
  }, []);

  useEffect(() => {
    if (user) {
      // console.log("USER LOADED", user);
    }
  }, [user]);

  return (
    <div className='main-content'>
      <NavBar user={user} />
      {/* <Switch>
        <Route exact path="/" component={UserInfo} />
      </Switch> */}
      {userChecked && (
        <Switch>
          <Route exact path='/' render={() => <ViewAll user={user} />} />
          {/* <Route
            exact
            path=""
            render={() => <ViewAll user={user} />}
          /> */}
        </Switch>
      )}
      <Footer />
    </div>
  );
};

export default App;
